<template>
  <div class="w-4/5 mx-auto">
    <div class="my-4 text-center">
      <span>{{ $t('shared.text.your_account_information') }}</span>
    </div>
    <div class="w-full grid grid-cols-1 gap-4 mobileLandscape:grid-cols-2">
      <InputField
        id="first_name"
        :placeholder="$t('shared.label.first_name')"
        :value="user.first_name"
        @input-change="firstName"
      />
      <InputField
        id="last_name"
        :placeholder="$t('shared.label.last_name')"
        :value="user.last_name"
        @input-change="lastName"
      />
      <InputFieldPhone
        id="phone"
        :placeholder="$t('shared.label.phone_number')"
        :value="user.phone"
        @validate="phoneValidation"
        @input-change="phoneNumer"
      />
      <InputField
        id="company_name"
        :placeholder="$t('shared.label.company_name')"
        :value="user.company_name"
        @input-change="companyName"
      />
      <Select class="field">
        <SelectSelection
          :value="langValue"
          @input-change="invoiceLanguage"
          data-cy="language_select"
        >
          <SelectOption disabled value="Language">
            <span>{{ $t('shared.label.language') }}</span>
          </SelectOption>
          <SelectOption
            v-for="language in langs"
            :key="language.code"
            :value="language.code"
          >
            <span>{{ $t(`shared.languages.${language.code}`) }}</span>
          </SelectOption>
        </SelectSelection>
      </Select>
      <Select class="field">
        <SelectSelection
          :value="timezoneValue"
          @input-change="onTimezoneChange"
          data-cy="timezone_select"
        >
          <SelectOption disabled value="Timezone">
            <span>{{ $t('shared.label.timezone') }}</span>
          </SelectOption>
          <SelectOption
            v-for="timezone in timezones"
            :key="timezone"
            :value="timezone"
          >
            <span>{{ timezone }}</span>
          </SelectOption>
        </SelectSelection>
      </Select>
    </div>
    <BtnsWrapperVertical>
      <BtnPrimary
        class="w-full"
        :title="$t('shared.button.finish_onboarding')"
        :disabled="enableBtn"
        data-cy="finish_onboarding"
        @click="save"
      />
      <BtnSecondary :title="$t('shared.button.previous')" @click="onClick" />
    </BtnsWrapperVertical>
  </div>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import InputField from '@/components/shared/input/InputField'
import InputFieldPhone from '@/components/shared/input/InputFieldPhone'
import Select from '@/components/shared/select/Select'
import SelectOption from '@/components/shared/select/SelectOption'
import SelectSelection from '@/components/shared/select/SelectSelection'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import languages from '@/utils/languages.json'
import beTimezones from '@/utils/timezones.json'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()
const $t = i18n.t

const props = defineProps({
  userInfo: Object
})
const emit = defineEmits(['show'])

const user = ref({
  first_name: null,
  last_name: null,
  company_name: null,
  phone: null,
  invoice_language: null,
  timezone: null
})
const phoneValidated = ref(false)

const currentUserAccounts = computed(
  () => store.state.workspace.currentUserAccounts
)
const currentUser = computed(() => store.state.workspace.currentUser)
const loading = computed(() => store.state.loading.loading)

const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)

const langs = computed(() => Object.values(languages))
const timezones = computed(() => beTimezones)
const langValue = computed(() => {
  if (user.value.invoice_language) return user.value.invoice_language
  return 'Language'
})
const timezoneValue = computed(() => {
  if (user.value.timezone) return user.value.timezone
  return 'Timezone'
})
const enableBtn = computed(() => {
  const isValid = Object.values(user.value).every((x) => {
    if (!x) return false
    if (x.value === null || x.value === '') return false
    return true
  })
  if (isValid && phoneValidated.value) return false
  return true
})

const registerFreemiumCustomer = (payload) =>
  store.dispatch('registration/registerFreemiumCustomer', payload)
const getCurrentUser = () => store.dispatch('workspace/getCurrentUser')
const startLoader = () => store.dispatch('loading/startLoader')
const stopLoader = () => store.dispatch('loading/stopLoader')

function firstName(name) {
  user.value.first_name = name.value
}
function lastName(name) {
  user.value.last_name = name.value
}
function companyName(name) {
  user.value.company_name = name.value
}
function phoneNumer(number) {
  user.value.phone = number.value
}
function invoiceLanguage(lang) {
  user.value.invoice_language = lang
}
function onTimezoneChange(timezone) {
  user.value.timezone = timezone
}
function phoneValidation(validation) {
  phoneValidated.value = validation
}
function onClick() {
  emit('show', { nextView: 'profile', account: user.value })
}
function save() {
  startLoader()
  let payload = { ...props.userInfo.profile, ...user.value }
  registerFreemiumCustomer(payload)
    .then(async () => {
      await getCurrentUser().then(() => {
        $cookies.set('userType', 'customer')
        $cookies.set('firstName', payload.first_name)
        $cookies.set('lastName', payload.last_name)
        $cookies.set('superAdmin', 'false')
        $cookies.set('isRegistered', 'true')
        $cookies.set('caasModal', 'false')
        if (
          process.env.NODE_ENV === 'production' &&
          window.smartlook !== undefined
        ) {
          window.smartlook('identify', currentUser.value.id, {
            name: `${currentUser.value.attributes.first_name} ${currentUser.value.attributes.last_name}`,
            email: currentUser.value.attributes.email,
            team_identifier: currentAccountId.value,
            user_type: $cookies.get('userType'),
            ...($cookies.get('userType') === 'customer'
              ? {
                  subscription_plan:
                    currentUserAccounts.value &&
                    currentUserAccounts.value[0]?.attributes?.subscription
                      ?.attributes?.plan_identifier
                }
              : {})
          })
        }
        stopLoader()
        router.push({
          name: 'contentGenerationV2',
          params: { teamIdentifier: currentAccountId.value }
        })
      })
    })
    .catch((err) => {
      stopLoader()
    })
    .finally(() => {
      stopLoader()
    })
}

watch(
  () => props.userInfo,
  (newVal) => {
    if (Object.keys(newVal.account).length > 0) {
      user.value = newVal.account
    }
  }
)
</script>
